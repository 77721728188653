.carousel-container{
  width: 100%;
}

.products-container{
  width: 100%;
  min-height: 500px;
}

.contact-container{
  width: 100%;
  min-height: 900px;
}

.about-container{
  width: 100%;
  min-height: 400px;
}


.products-container h1, .contact-container h1, .about-container h1 {
  font-weight: 700;
}

.line {
  display: inline-block;
  border-top: 1px solid aqua;
  width: 100%;
}

