.products{
    margin-block: 50px;
}

.pro-cards {
    margin-block: 50px;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 100px;
}

.pro {
    height: 360px;
    width: 300px;
    cursor: pointer;
}

.pro-top img{
    width: 300px;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.pro-top img:hover{
    transition: 0.3s ease-out;
    transform: translateY(-10px);
}

.pro-bottom{
    font-size: 25px;
    font-weight: 700;
    margin-top: 20px;
    text-align: center;
}

.items {
    row-gap: 50px;
}
