.contact {
  margin-block: 80px;
  row-gap: 50px;
  width: 100%;
}

.contact-info {
  column-gap: 50px;
  width: 100%;
}

.info {
  width: 245px;
  row-gap: 20px;
  text-align: center;
}

.info-title {
  font-size: 30px;
  font-weight: 700;
  color: blue;
}

.info-text {
  font-size: 20px;
  font-weight: 600;
  width: 245px;
}

.social-media {
  font-size: 30px;
  column-gap: 30px;
}

.facebook {
  background-color: #4267b2;
}

.instagram {
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
}

.social {
  color: white!important;
  width: 50px;
  height: 50px;
  text-decoration: none;
  border-radius: 50%;
  opacity: 0.5;
}

a:hover {
  opacity: 1;
  color: inherit;
}

.google-map{
  position: relative;
  height: 600px;
  width: 900px;
}

.google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 900px) {
  .google-map {
    height: 300px;
    width: 100%;
  }

  .info{
    row-gap: 5px;
  }
}