.products-page-container {
    min-height: 1000px;
    padding: 140px 20px 50px 20px;
    display: grid;
    align-items: center;
    width: 100%;
}

.product-info {
    padding-bottom: 50px;
    font-size: 30px;
    text-align: center;
}

.images {
    padding-inline: 20px;
    column-gap: 20px;
    row-gap: 50px;
    flex-wrap: wrap;
} 

.type-buttons{
    min-width: 350px;
}

.card{
    cursor: pointer;
}

@media (max-width: 900px) {

    .product-info{
        font-size: 20px;
    }
}