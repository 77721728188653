.mynavbar {
    min-height: 100px;
    background-color: black;
    width: 100%;
}

.nav-link {
    color: white!important;
    font-weight: 600!important;
}

.nav-link:hover {
    color: aqua!important;
}

.navlogo {
    height: 4rem;
}

.navbar-collapse {
    justify-content: flex-end;
}

.scrolling-text-container {
    position: fixed;
    padding-top: 100px;
    background-color: #00ccff;
  }
  
  
  .scrolling-text {
    display: flex;
    justify-content: flex-start;
    color: black;
    font-family: Comic Sans MS;
    font-size: 4vw;
    top: 0;
    background-color: #00ccff; /* Optional background color for the scrolling text */
    padding: 10px 0;
    animation: scroll-left 6000ms linear infinite;
    white-space: nowrap;
  }
  
  @keyframes scroll-left {
    0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
  }
  
  .scrolling-text span { 
    display: inline-block;
    padding-right: 100%; /* Ensure the text wraps correctly */
  }
  
  .content {
    padding-top: 40px; /* Adjust the top padding to avoid overlap */
  }
  

@media (max-width: 575px) {
    .navbar-nav {
        align-items: center;
    }
}