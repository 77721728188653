.carousel-item {
  background-color: rgba(0, 0, 0);
}

.carousel-image {
  opacity: 0.55;
  height: 950px;
  object-fit: cover;
}

.carousel-caption {
  font-family: 'Courgette', cursive;
  font-weight: 300;
  font-style: italic;
  color: rgb(245, 248, 70) !important;
  font-size: 50px !important;
  position: absolute !important;
  bottom: 25% !important;
}

.carousel-logo {
  position: relative;
  width: 30rem;
}

.phone-number {
  color: rgb(180, 180, 180)!important;
  font-weight: 700;
}

.antbay {
  font-size: 60px;
}

@media (max-width: 900px) {
  .carousel-caption {
    font-size: 30px!important;
  }

  .antbay {
    font-size: 40px;
  }

 }
