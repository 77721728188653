.About {
    width: 100%;
    font-size: 20px;
    row-gap: 50px;
    margin-block: 40px;
}

.about-text {
    width: 900px;
}

@media (max-width: 900px) {
.about-text {
    font-size: 15px;
    width: 400px
}
}