.customfooter {
    background-color: black;
    height: 100px;
    width: 100%;
    color: white;
    position: absolute;
    bottom: 0;
}

.me{
    margin-left: 5px;
    color: white;
}

.me:hover{
    color: inherit;
    opacity: 0.75;
}