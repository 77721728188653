body{
    font-family: 'Titillium Web', sans-serif!important;
}

a {
    text-decoration: none;
    color: inherit;
}


html {
    behavior: smooth;
}